import "typeface-raleway"
import "firebase/functions"
import firebase from "gatsby-plugin-firebase"
import checkNsetCookie from "./src/utils/affTrackingScript"
if (process.env.NODE_ENV === "development") {
  firebase.functions().useFunctionsEmulator("http://localhost:5001")
}

const ReactGA = require("react-ga")
//google analytics
ReactGA.initialize("UA-163567195-1", {
  // debug: true,
})
//pageview on route change
export const onRouteUpdate = (state, page, pages) => {
  checkNsetCookie() //check for EVID and set if found
  ReactGA.pageview(state.location.pathname)
}

//Mobile VH fix.....
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`)
