function setCookie(cname, cvalue, exdays, domain) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = "expires=" + d.toUTCString()
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";domain=" + domain + ";path=/"
}

function getCookie(cname) {
  var name = cname + "="
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return undefined
}

function getUrlVars() {
  var vars = {}
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
    vars[key] = value
  })
  return vars
}

export default () => {
  var EVID = getUrlVars()["EVID"]
  const domain = window.location.hostname
  if (typeof EVID !== "undefined") {
    setCookie("EVID", EVID, 60, domain)
  }
}
